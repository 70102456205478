#faq {
  padding-top: 139px;
}

#faq h1 {
  font-family: "Antonio";
  font-weight: 700;
  font-size: 56px;
  line-height: 72px;
  text-transform: uppercase;
  color: #e2dad6;
}

.faq-section {
  padding-top: 40px;
  width: 80%;
}

.faq-row {
  background: #e2dad6;
  border-radius: 16px !important;
  margin-bottom: 5px !important;
  padding: 7px 0px 7px 26px !important;
  width: 77%;
  margin: auto;
}

.row-title-text {
  font-family: "Antonio";
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  text-transform: uppercase;
  color: #3d3d3d;
}

.row-content-text {
  text-align: left;
  font-family: "Antonio";
  font-weight: 700;
  font-size: 20px !important;
  line-height: 32px;
  padding-right: 40px !important;
  padding-bottom: 10px !important;
  color: #3d3d3d !important;
}

.icon-wrapper {
  background: url(../../assets/image/faq-arrow.png) no-repeat;
  top: 27px !important;
  right: 26px !important;
}

.arrow-image > svg > path {
  display: none;
}

@media (max-width: 768px) {
  .row-title-text {
    font-size: 20px;
  }
  .row-content-text {
    font-size: 17px !important;
  }
  .faq-row {
    padding: 1px 0px 1px 11px !important;
  }
  .icon-wrapper {
    top: 20px !important;
  }
}

@media (max-width: 576px) {
  .row-title-text {
    font-size: 15px !important;
  }
  .row-content-text {
    font-size: 14px !important;
  }
}

@media (max-width: 430px) {
  #faq {
    padding-top: 42px;
  }
  #faq h1 {
    font-size: 20px;
    line-height: 26px;
    padding-bottom: 20px;
  }
  .faq-section {
    width: 90%;
    margin: auto;
    padding-top: 0px;
  }
  .faq-row {
    width: 95%;
    border-radius: 17px !important;
  }
  .row-title-text,
  .row-content-text {
    font-size: 11px !important;
    line-height: 16px !important;
  }
  .icon-wrapper {
    top: 16px !important;
    right: 10px !important;
    background-size: 28% 41%;
    background-position: top center;
  }
}
