#mask {
  padding-top: 109px;
}

.mask-desktop {
  width: 44%;
  border-radius: 21px;
}

.mask-mobile {
  display: none;
}

@media (max-width: 576px) {
  .mask-desktop {
    display: none;
  }
  .mask-mobile {
    display: block;
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 430px) {
  #mask {
    padding-top: 40px;
  }
}
