#termsSection {
  text-align: left;
}

#termsSection .custom-container {
  width: 85%;
}

#termsSection h1 {
  font-family: "Antonio";
  font-weight: 700;
  font-size: 44px;
  line-height: 83px;
  color: #e2dad6;
  text-align: left;
  padding-top: 2.5rem;
  margin: 0;
}

#termsSection p {
  font-family: "Antonio";
  font-weight: 300;
  font-size: 20px;
  line-height: 31px;
  color: #d5d5d5;
  text-align: left;
  padding-top: 1.25rem;
  margin: 0;
}

@media (max-width: 768px) {
  #termsSection {
    padding-top: 10%;
  }
  #termsSection h1 {
    font-size: 32px;
  }
}
 
@media (max-width: 430px) {
  #termsSection h1 {
    font-size: 24px;
    line-height: 26px;
  }
  #termsSection p {
    font-size: 16px;
    line-height: 25px;
  }
}
