.nav-link {
  font-family: "Antonio";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #e2dad6;
  text-align: left;
  cursor: pointer;
}
.navbar-nav {
  width: 90%;
  align-items: center;
  justify-content: end;
  gap: 42px;
  transition: 0.7s;
}

.connect-btn {
  padding: 10px 23px 10px 16px !important;
  color: #212121;
  background: #e2dad6;
  border-radius: 12px;
}

.navbar-logo {
  width: 100%;
  padding: 11px 13px 15px 13px;
}

@media (max-width: 1700px) {
  #navbar .custom-container {
    width: 95%;
  }
}

@media (max-width: 1600px) {
  .nav-link {
    font-size: 20px;
  }
  .navbar-logo {
    width: 85%;
    padding-right: 0;
  }
}

@media (max-width: 1440px) {
  .navbar-nav {
    width: 100% !important;
    justify-content: center;
    align-items: center;
  }
  .nav-link {
    font-size: 17px;
  }
  .navbar-btn {
    padding: 7px 30px;
  }
}

@media (max-width: 1350px) {
  .navbar-nav {
    justify-content: flex-end;
  }
}

@media (max-width: 1200px) {
  .navbar-nav {
    gap: 30px !important;
  }
}

@media (max-width: 768px) {
  .navbar-btn {
    display: none !important;
  }
  .navbar-logo {
    width: 41%;
    position: absolute;
    left: 0;
    top: 0;
  }
}

@media (max-width: 430px) {
  .navbar-logo {
    width: 60%;
  }
}
