#banner {
  margin-top: 0%;
  position: relative;
  text-align: center;
  padding-bottom: 7%;
}

.banner-image {
  width: 100%;
  position: relative;
  z-index: 1;
  padding-right: 3%;
}

.banner-title {
  margin-top: -17% !important;
  font-family: "Antonio";
  font-weight: 600 !important;
  font-size: 30px !important;
  line-height: 39px !important;
  text-align: center;
  letter-spacing: -0.04em;
  color: #dcb901;
  position: relative;
  z-index: 2;
}

.banner-line {
  position: absolute;
  overflow-x: hidden;
  width: 100%;
  z-index: 4;
}

.banner-line > img {
  width: 100%;
  padding-top: 4%;
  filter: drop-shadow(0px 11px 7px rgba(0, 0, 0, 0.25));
}

.pink-mobile {
  display: none;
}

.banner-title h3:nth-last-child(1) {
  max-width: 1010px;
  margin: auto;
}

@media (max-width: 768px) {
  #banner {
    margin-top: 4%;
  }
}

@media (max-width: 430px) {
  #banner {
    margin-top: 55%;
  }
  .banner-title {
    width: 77%;
    margin: auto !important;
    margin-top: 47% !important;
  }
  .banner-title > h3 {
    font-size: 14px !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    margin-bottom: 0;
  }
  .pink {
    display: none;
  }
  .pink-mobile {
    display: block;
    width: 100%;
  }
  .banner-image {
    padding-top: 17%;
    scale: 2.6;
    padding-right: 3.5%;
  }
  .banner-line > img {
    padding-top: 6%;
  }
}
