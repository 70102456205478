#art {
  margin-top: 130px;
  position: relative;
  text-align: center;
}

#art h1 {
  font-family: "Antonio";
  font-weight: 700;
  font-size: 56px;
  line-height: 72px;
  color: #e2dad6;
  position: relative;
}

.slider-header {
  position: absolute;
  left: 0;
  top: 121px;
  width: 100%;
}

.group1 {
  margin-top: 202px;
}

.group2 {
  margin-top: 150px;
}

.group3 {
  margin-top: 97px;
}

.group4 {
  margin-top: 164px;
}

.group5 {
  margin-top: 67px;
}

.group6 {
  margin-top: 226px;
}

.mobile-group1 {
  margin-top: 20px;
}

.mobile-group3 {
  margin-top: 25px;
}

.box-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(64, 64, 64, 0) 0%,
    rgba(64, 64, 64, 0) 10%,
    rgba(64, 64, 64, 0.5) 40%,
    rgba(64, 64, 64, 0) 100%
  );

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
}

.mobile-art {
  display: none;
}

/* Animation */
.marquee {
  border-radius: 20px;
  overflow: hidden;
  height: 900px;
  position: relative;
}

.marquee::before,
.marquee::after {
  content: "";
  width: 100%;
  height: 30%;
  position: absolute;
  left: 0;
  z-index: 1;
}

.marquee::before {
  background: linear-gradient(180deg, #1f1f1f 0%, rgba(31, 31, 31, 0) 100%);
  top: 0;
}
.marquee::after {
  bottom: 0;
  background: linear-gradient(180deg, rgba(31, 31, 31, 0) 0%, #1f1f1f 100%);
}

.marquee img {
  width: 100%;
  height: auto;
  display: inline-block;
  position: relative;
  top: -100%;
  animation: marqueeTopBottomFullScreen 13s linear infinite;
}

.marquee-down img {
  top: 0%;
  animation: marqueeBottomTopFullScreen 13s linear infinite;
}

.marquee:hover img {
  animation-play-state: paused;
}

.marquee img:nth-child(1) {
  animation-delay: 0s;
  top: 910px;
}
.marquee img:nth-child(2) {
  animation-delay: 0s;
  transform: translateY(-900px);
}

.marquee.marquee-down img:nth-child(1) {
  animation-delay: 0s;
  top: -24px;
}
.marquee.marquee-down img:nth-child(2) {
  animation-delay: 0s;
  opacity: 0;
  transform: translateY(0px);
}

@keyframes marqueeTopBottomFullScreen {
  0% {
    transform: translateY(-900px);
    opacity: 1;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-900px);
    opacity: 1;
  }
}
@keyframes marqueeBottomTopFullScreen {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(-900px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (max-width: 1536px) {
  .marquee img {
    animation: marqueeTopBottom1536Screen 12s linear infinite;
  }
  .marquee-down img {
    animation: marqueeBottomTop1536Screen 12s linear infinite;
  }
  .marquee {
    height: 800px;
  }
  .marquee img:nth-child(1) {
    top: 1005px;
  }
}

@keyframes marqueeTopBottom1536Screen {
  0% {
    transform: translateY(-750px);
    opacity: 1;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-750px);
    opacity: 1;
  }
}
@keyframes marqueeBottomTop1536Screen {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(-750px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (max-width: 1280px) {
  .marquee img {
    animation: marqueeTopBottom1280Screen 10s linear infinite;
  }
  .marquee-down img {
    animation: marqueeBottomTop1280Screen 10s linear infinite;
  }
  .marquee {
    height: 600px;
  }
  .marquee img:nth-child(1) {
    top: 923px;
  }
  .marquee.marquee-down img:nth-child(1) {
    top: -20px;
  }
}

@keyframes marqueeTopBottom1280Screen {
  0% {
    transform: translateY(-620px);
    opacity: 1;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-620px);
    opacity: 1;
  }
}

@keyframes marqueeBottomTop1280Screen {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(-620px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .marquee img {
    animation: marqueeTopBottom600Screen 15s linear infinite;
  }
  .marquee-down img {
    animation: marqueeBottomTop600Screen 15s linear infinite;
  }
  .marquee {
    height: 452px;
  }
  .marquee img:nth-child(1) {
    animation-delay: 0s;
    top: 420px;
  }
  .marquee img:nth-child(2) {
    animation-delay: 0s;
    transform: translateY(-510px);
  }
  .marquee.marquee-down img:nth-child(1) {
    animation-delay: 0s;
    top: -10px;
  }
  .marquee-down img:nth-child(2) {
    animation-delay: 0s;
    transform: translateY(0px);
  }
}
@keyframes marqueeTopBottom600Screen {
  0% {
    transform: translateY(-400px);
    opacity: 1;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-400px);
    opacity: 1;
  }
}
@keyframes marqueeBottomTop600Screen {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(-400px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .hidden {
    display: none;
  }
  .box-gradient {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    width: 83%;
    margin: auto;
  }
}

@media (max-width: 430px) {
  #art {
    margin-top: 54px;
  }
  .group1,
  .group2,
  .group3 {
    display: none;
  }
  .mobile-art {
    display: block;
  }
  .box-gradient {
    display: grid;
    width: 76%;
    margin: auto;
    grid-gap: 16px;
  }
  #art h1 {
    font-size: 20px;
    line-height: 26px;
    padding-bottom: 50px;
  }
}

@media (max-width: 390px) {
  .marquee {
    height: 376px;
  }
  .marquee img:nth-child(1) {
    top: 410px;
  }
}

@media (max-width: 375px) {
  .marquee {
    height: 340px;
  }
  .marquee img:nth-child(1) {
    top: 412px;
  }
  .marquee.marquee-down img:nth-child(1) {
    top: -9px;
  }
}

@media (max-width: 360px) {
  .marquee img:nth-child(1) {
    top: 375px;
  }
  .marquee.marquee-down img:nth-child(1) {
    top: -9px;
  }
}

@media (max-width: 321px) {
  .marquee {
    height: 280px;
  }
  .marquee img:nth-child(1) {
    top: 337px;
  }
}
