#card {
  padding-top: 7%;
}

.item1 > div:nth-last-of-type(1) {
  text-align: left;
}

#card h1 {
  font-family: "Antonio";
  font-weight: 700;
  font-size: 64px;
  line-height: 83px;
  color: #e2dad6;
  text-align: left;
}

#card img {
  width: 47%;
}

.item-content {
  padding-top: 40px;
}

.card-item {
  position: relative;
  z-index: 4;
}

.item-content > p {
  font-family: "Antonio";
  font-weight: 300;
  font-size: 20px;
  line-height: 31px;
  color: #d5d5d5;
  padding: 0;
  margin: 0;
  text-align: left;
}

.item2,
.item3 {
  padding-top: 110px;
}

.item2 > div:nth-of-type(1) {
  padding-left: 13%;
}

.item3 > div:nth-last-of-type(1) {
  padding-right: 13%;
}

.card-bottom {
  padding-top: 13%;
  display: flex;
  justify-content: center;
  width: 75%;
  margin: auto;
}

.card-bottom p {
  font-family: "Antonio";
  font-style: normal;
  font-weight: 100;
  font-size: 25px;
  line-height: 44px;
  text-align: center;
  text-transform: uppercase;
  color: #e2dad6;
  margin-bottom: 0;
}

.fucked {
  color: #fced22;
}

@media (max-width: 1200px) {
  .card-item {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .item2 {
    flex-direction: column;
  }
  #card h1 {
    text-align: center;
  }
  .item-content > p {
    text-align: center;
  }
  .item2 > div:nth-of-type(1),
  .item3 > div:nth-last-of-type(1) {
    padding-left: 0;
    padding-right: 0;
  }
  #card img {
    padding-top: 40px;
  }
  .card-bottom p {
    font-size: 20px;
    line-height: 30px;
  }
}

@media (max-width: 1024px) {
  .banner-title > h3 {
    font-size: 20px !important;
  }
}

@media (max-width: 768px) {
  .card-bottom p {
    font-size: 11px;
    line-height: 20px;
  }
  .banner-title > h3 {
    font-size: 16px !important;
  }
  #card h1 {
    text-align: center;
  }
  .item-content > p {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 430px) {
  #card h1 {
    font-size: 20px;
    line-height: 26px;
  }
  .item-content > p {
    font-size: 14px;
    line-height: 18px;
  }
  .item-content {
    width: 67%;
    margin: auto;
    padding-top: 19px;
  }
  #card img {
    width: 74%;
    padding-top: 25px;
  }
  .item2,
  .item3 {
    padding-top: 44px;
  }
  .card-bottom p {
    font-size: 14px !important;
    line-height: 15px !important;
  }
  .card-bottom > div {
    flex-direction: column;
    padding-top: 44px;
    width: 70%;
    margin: auto;
  }
  .card-bottom > p {
    padding-top: 14px;
  }
}
