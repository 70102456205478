#mintSection {
  background: url("../../assets/image/mint.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 80vh;
  position: relative;
}

#mintSection h1 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 48px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.05em;
  color: #e2dad6;
  font-weight: 700;
  padding-top: 50px;
}

.mint-card {
  padding: 2.5rem 5rem;
  margin-top: 40px !important;
  border-radius: 1rem;
  background-color: #e2dad6;
  width: fit-content;
  margin: auto;
}

.mint-input {
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.25rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  display: flex;
  justify-content: space-between;
  color: #3d3d3d;
  background-color: #b7a297;
}

.mintBtn {
  border-radius: 0.375rem;
  padding: 0rem 2rem;
  background-color: #3d3d3d;
  color: #e2dad6;
}

.mint-price {
  display: flex;
  justify-content: space-between;
  color: #3d3d3d;
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.25rem;
  padding-top: 10px;
}

.mint-address {
  font-family: "Bebas Neue", sans-serif;
  color: #3d3d3d;
  font-size: 1.25rem;
  padding-top: 15px;
}

.mint-address > span {
  color: #b7a297;
}

#mint #footer {
  padding: 20px 0px !important;
}

@media (max-width: 768px) {
  #mintSection {
    margin-top: 110px;
  }
  #mint #footer {
    margin-top: 0px;
  }
  #mintSection h1 {
    font-size: 35px;
  }
  .mint-card {
    width: 90%;
    margin: auto;
    padding: 7% 10%;
  }
  .mint-address,
  .mint-price {
    font-size: 14px;
    text-align: left;
  }
}

@media (max-width: 450px) {
  .mint-address {
    font-size: 12px;
  }
  #mintSection {
    margin-top: 70px;
  }
}
