#team {
  margin-top: 20%;
  position: relative;
  text-align: center;
}

#team h1 {
  font-family: "Antonio";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 72px;
  color: #e2dad6;
}

.team-img {
  padding-top: 50px;
  position: relative;
}

.team-image {
  width: 100%;
}

.social {
  padding: 9px 20px 9px 12px;
  background: #e2dad6;
  border-radius: 10px;
  font-family: "Antonio";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #3d3d3d;
  transition: 1s;
  animation-name: fadeIn;
  animation-duration: 1.5s;
  position: absolute;
  transform: translateX(30%);
  bottom: 20%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.social > div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.social > img {
  width: 20px;
  height: 20px;
}

.social-item1,
.social-item2,
.social-item3,
.social-item4,
.social-item5 {
  display: none;
}

.hover-area {
  position: absolute;
  width: 100%;
  height: 90%;
  top: 10%;
  display: flex;
}

.empty1 {
  width: 9%;
  height: 100%;
}

.shey {
  position: relative;
  width: 12.5%;
  height: 100%;
}

.rob {
  position: relative;
  width: 10%;
  height: 100%;
}

.empty2 {
  width: 34%;
  height: 100%;
}

.alex {
  position: relative;
  width: 10%;
  height: 100%;
}

.empty3 {
  width: 4.4%;
  height: 100%;
}

.marjaneh {
  position: relative;
  width: 8%;
  height: 100%;
}

.marjaneh > .social {
  transform: translateX(0%) !important;
}

.tina {
  position: relative;
  width: 10%;
  height: 100%;
}

.shey:hover .social-item1 {
  display: block;
}

.rob:hover .social-item2 {
  display: block;
}

.alex:hover .social-item3 {
  display: block;
}

.marjaneh:hover .social-item4 {
  display: block;
}

.tina:hover .social-item5 {
  display: block;
}

@media (max-width: 1440px) {
  .social {
    transform: translateX(10%) !important;
  }
}

@media (max-width: 1200px) {
  .social {
    padding: 6px 11px 6px 7px;
    font-size: 14px;
    line-height: 20px;
    bottom: 15%;
  }
  .social > div {
    gap: 5px;
  }
  .social > img {
    width: 17px;
    height: 17px;
  }
}

@media (max-width: 920px) {
  .social {
    transform: translateX(0%) !important;
  }
  .marjaneh > .social {
    transform: translateX(-20%) !important;
  }
}

@media (max-width: 768px) {
  .social {
    transform: translateX(-15%) !important;
  }
  .marjaneh > .social, .tina > .social {
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 576px) {
  .social {
    padding: 4px 6px 4px 4px;
  font-size: 14px;
    line-height: 20px;
    bottom: 15%;
    gap: 5px;
  }
  .social {
    transform: translateX(-20%) !important;
  }
  .marjaneh > .social, .tina > .social {
    transform: translateX(-40%) !important;
  }
}

@media (max-width: 450px) {
  .marjaneh > .social, .tina > .social {
    transform: translateX(-44%) !important;
  }
}

@media (max-width: 430px) {
  #team h1 {
    font-size: 20px;
    line-height: 26px;
  }
  .team-img {
    padding-top: 24px;
  }
}
