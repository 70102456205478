.App {
  text-align: center;
}

.custom-container {
  width: 90%;
  padding: 0px 15px;
  margin: auto;
  position: relative;
}



